// import { Modal } from '@mui/base'
import React, { useState } from 'react'

import mensImage from './TShirtImages/mens-size.png'
import womensImage from './TShirtImages/womens-size.png'
import { Button, Chip, Modal } from '@mui/material'

export default function TShirtModal({ gender = 'male' }) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Button onClick={() => setOpen(true)} >Size Chart</Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >

                <img src={(gender === 'male') ? mensImage : womensImage} alt='T-Shirt Size Chart' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '75%', maxHeight: '75%' }} />
                {/* <Button onClick={() => setOpen(false)}>Close</Button> */}
            </Modal>
        </>
    )
}
