import { atom } from "recoil";

export const authUserState = atom({
    key: 'authUserState',
    default: { uid: '' }
})

export const themeState = atom({
    key: 'themeState',
    default: true,
})

export const isSportRegisterState = atom({
    key: 'isSportRegisterState',
    default: false
})