export const convertTimestamp = (timestamp) => {
    if (timestamp) {
        if ((timestamp?.seconds) || (timestamp?._seconds))
            return new Date((timestamp.seconds ?? timestamp._seconds) * 1000)
        if (!isNaN(new Date(Number(timestamp))))
            return new Date(Number(timestamp))
        if (!isNaN(new Date(timestamp)))
            return new Date(timestamp)
    }
    return new Date()
}

export function calculateAge(birthdate) {
    const today = new Date('29 Dec 2023');
    const dob = new Date(convertTimestamp(birthdate));

    let years = today.getFullYear() - dob.getFullYear();
    const months = today.getMonth() - dob.getMonth();
    const days = today.getDate() - dob.getDate();

    // Adjust for negative months or days
    if (months < 0 || (months === 0 && days < 0)) {
        years--;
    }

    return years;
}

export function calculateAgeGroup(sport, userBirthdate, gender = 'boys') {
    // const today = new Date();
    const age = calculateAge(userBirthdate)

    for (const ageGroup of sport.ageGroups[gender]) {
        if (age >= ageGroup.minAge && age <= ageGroup.maxAge) {
            return ageGroup;
        }
    }

    return 'Not eligible for any age group';
}

// Example usage:
// const userBirthdate = new Date('2005-08-15'); // Replace with the user's birthdate
// const selectedSport = sportsArray.find(sport => sport.name === 'Athletics');
// const ageGroup = calculateAgeGroup(selectedSport, userBirthdate);
// console.log(`User belongs to the age group: ${ageGroup}`);