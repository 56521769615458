import { selector } from "recoil";
import { authUserState } from "./atom";

export const loggedInState = selector({
    key: 'loggedInState',
    get: ({ get }) => {
        const authUser = get(authUserState)
        return !!authUser.uid
    }
})

export const isForTesting = selector({
    key: 'isForTestingState',
    get: ({ get }) => {

        const masterUID = {
            "t3R9AK8XEeW6gbweeUNWxbmvz4P2": 'tnevysya',
            "XyVT6ywDNNVuPle8nV2DkIyfKfp2": 'boost',
            "XqLHxgCxxVQoy7pKgIHa9RtYKUA2": 'gk an',
            "XPuUUMi0Y8NH3vdIWEQVEtTbc703": 'ranjith',
            // "OPk4Vvfr2vSQa2IFjImwXrCYfas2": 'santhos',
            // "azBEEs8vZxN0GR8uiRLf0p0ijr13": 'siva guru kumar',
            // "tyIKyCkkY7ZlyEq2kZ7A4BYvi0m2": 'Thilip',
            // "I7UA9BodsoWsykmeSiecgHEhEs43": 'Aadhar',
            // "KNBCwcgadvePPM44QLB42NCvuD42": 'Ananth',
            // "MoBV21BkjQRFfOGSKMUxHSHnOcD3": 'Balaji',
            // "zd6cvUzOuISNmT6NWsMkCmDxDmR2": 'naga ar',
            // "XPuUUMi0Y8NH3vdIWEQVEtTbc703": 'ranjith',
            // "hOJQFL53kuhneJcthqcDmgCbyiZ2": 'teni',
            // "kmWutk3njUbNM4T1NIZ9IZnV5D02": 'GK Persional'
        }

        const devilUID = {
            "XyVT6ywDNNVuPle8nV2DkIyfKfp2": 'gk'
        }

        const islocal = !!(process.env.NODE_ENV === 'development')

        const { uid } = get(authUserState)

        const isMaster = !!masterUID[uid]
        const isDevil = !!devilUID[uid]

        return { islocal, isMaster, isDevil }

    }
})