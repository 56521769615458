import { atom } from "recoil";

import athleticsImg from '../../components/Sports/athletic2.jpg'
import chessImg from '../../components/Sports/chess3.jpg'
import carromImg from '../../components/Sports/carrom.jpg'
import shuttleImg from '../../components/Sports/shuttle.jpg'
import swimmingImg from '../../components/Sports/swimming.jpg'
import yogoImg from '../../components/Sports/yoga2.jpg'
import silambamImg from '../../components/Sports/silambam.jpg'
import skatingImg from '../../components/Sports/skating.jpg'
import kabadiImg from '../../components/Sports/kabadi.jpg'

const sportsData = [
    {
        title: 'Athletics',
        img: athleticsImg,
        description: 'Join us for exciting track and field events.',
        tac: [
            {
                title: "Footwear Requirement",
                content: "Participants must wear sports shoes, and entry without suitable footwear is not allowed."
            },
            {
                title: "Safety Kit",
                content: "It is recommended that participants bring a complete safety kit for their protection."
            },
            {
                title: "Starting Blocks",
                content: "The use of starting blocks is not allowed in this event."
            }
        ],
        ageGroups: {
            male: [
                { minAge: 3, maxAge: 11, label: 'Upto 11 years ' },
                { minAge: 12, maxAge: 14, label: '12 to 14 years ' },
                { minAge: 15, maxAge: 17, label: '15 to 17 years ' },
                { minAge: 18, maxAge: 24, label: '18 to 24 years ' },
                { minAge: 25, maxAge: 100, label: '25 and above ' }
            ],
            female: [
                { minAge: 3, maxAge: 11, label: 'Upto 11 years ' },
                { minAge: 12, maxAge: 14, label: '12 to 14 years ' },
                { minAge: 15, maxAge: 17, label: '15 to 17 years ' },
                { minAge: 18, maxAge: 24, label: '18 to 24 years ' },
                { minAge: 25, maxAge: 100, label: '25 and above ' }
            ],
        }
    },
    {
        title: 'Shuttlecock',
        img: shuttleImg,
        description: 'Experience fast-paced badminton matches in the shuttle event.',
        tac: [
            {
                title: "Racket Requirement",
                content: "Participants must bring their own badminton racket and Mavis 350 cock Green Tape (New One Preferred)."
            },
            {
                title: "Footwear",
                content: "Wearing appropriate badminton shoes (shuttle shoes) is mandatory."
            }
        ],
        ageGroups: {
            male: [
                { minAge: 3, maxAge: 15, label: 'Upto 15 years ' },
                { minAge: 16, maxAge: 18, label: '16 to 18 years ' },
                { minAge: 19, maxAge: 23, label: '19 to 23 years ' },
                { minAge: 24, maxAge: 35, label: '24 to 35 years ' },
                { minAge: 36, maxAge: 100, label: '36 and above ' }
            ],
            female: [
                { minAge: 3, maxAge: 15, label: 'Upto 15 years ' },
                { minAge: 16, maxAge: 18, label: '16 to 18 years ' },
                { minAge: 19, maxAge: 23, label: '19 to 23 years ' },
                { minAge: 24, maxAge: 35, label: '24 to 35 years ' },
                { minAge: 36, maxAge: 100, label: '36 and above ' }
            ],
            combined: [
                { minAge: 3, maxAge: 15, label: 'Upto 15 years (Combined)' },
                { minAge: 16, maxAge: 18, label: '16 to 18 years (Combined)' },
                { minAge: 19, maxAge: 23, label: '19 to 23 years (Combined)' },
                { minAge: 24, maxAge: 35, label: '24 to 35 years (Combined)' },
                { minAge: 36, maxAge: 100, label: '36 and above (Combined)' }
            ]
        }
    },
    {
        title: 'Swimming',
        img: swimmingImg,
        description: 'Make a splash and race in the swimming competition.',
        tac: [
            {
                title: "Swimsuit Requirement",
                content: "Only swimsuits are allowed for swimming events. Participants must bring their own swimsuits."
            },
            {
                title: "Swimming Proficiency",
                content: "Participants must submit proof of their swimming proficiency to be eligible for the event."
                // content: "Participants must demonstrate their swimming proficiency at the time of the event to participate."
            }
        ],
        ageGroups: {
            male: [
                { minAge: 3, maxAge: 13, label: 'Upto 13 years ' },
                { minAge: 14, maxAge: 16, label: '14 to 16 years ' },
                { minAge: 17, maxAge: 20, label: '17 to 20 years ' },
                { minAge: 21, maxAge: 25, label: '21 to 25 years ' },
                { minAge: 26, maxAge: 100, label: '26 and above ' }
            ],
            female: [
                { minAge: 3, maxAge: 13, label: 'Upto 13 years ' },
                { minAge: 14, maxAge: 16, label: '14 to 16 years ' },
                { minAge: 17, maxAge: 20, label: '17 to 20 years ' },
                { minAge: 21, maxAge: 25, label: '21 to 25 years ' },
                { minAge: 26, maxAge: 100, label: '26 and above ' },
            ],
        },
    },
    {
        title: 'Carrom',
        img: carromImg,
        description: 'Compete in a game of precision and tactics with carrom.',
        tac: [
            {
                title: "Knockout System",
                content: "The knockout system will be used for Carrom matches."
            },
            {
                title: "Playing Method",
                content: "Thumping and scissors techniques will be allowed during the game."
            },
            {
                title: "Scoring Methods",
                content: "Matches can be won by reaching six board-leading points, 21 points, or within 25 minutes, whichever comes first."
            },
            {
                title: "Striker Requirement",
                content: "All players must bring their own striker with a diameter not exceeding 4.13 cm and a weight not exceeding 15 grams."
            },
        ],
        ageGroups: {
            male: [
                { minAge: 3, maxAge: 12, label: 'Upto 12 years ' },
                { minAge: 13, maxAge: 16, label: '13 to 16 years ' },
                { minAge: 17, maxAge: 24, label: '17 to 24 years ' },
                { minAge: 25, maxAge: 45, label: '25 to 45 years ' },
                { minAge: 46, maxAge: 100, label: '46 and above ' }
            ],
            female: [
                { minAge: 3, maxAge: 12, label: 'Upto 12 years ' },
                { minAge: 13, maxAge: 16, label: '13 to 16 years ' },
                { minAge: 17, maxAge: 24, label: '17 to 24 years ' },
                { minAge: 25, maxAge: 45, label: '25 to 45 years ' },
                { minAge: 46, maxAge: 100, label: '46 and above ' }
            ],
        }
    },
    {
        title: 'Yoga',
        img: yogoImg,
        description: 'Find your inner peace and flexibility in the yoga event.',
        tac: [
            {
                title: "Aasana's To Do",
                content: "From the given list of 8 Asana participant have to perform 5 Asana and 1 Advanced Asana of your own choice.",
            },
            {
                title: "Yoga Mat",
                content: "Please bring your own yoga mat for yoga events."
            },
            {
                title: "Attire",
                content: "Comfortable and decent attire is allowed during yoga competitions."
            }
        ],
        showList: 'Aasanas',
        ageGroups: {
            male: [
                { minAge: 3, maxAge: 12, label: 'Upto 12 years ', Aasanas: ['Trikonasana', 'Virbhadrasana', 'Padahastasana', 'Akara Dhanurasana', 'Kukkutasana', 'Eka pada sikandasana', 'Vibhakta Paschimottanasana', 'Koundinyasana'] },
                { minAge: 13, maxAge: 18, label: '13 to 18 years ', Aasanas: ['Pachimottasana', 'Halasana', 'Purna Charkrasana', 'Parsvakonasana', 'Matsyendrasana', 'Purna Dhanurasana', 'Bakasana', 'Titishasana',] },
                { minAge: 19, maxAge: 24, label: '19 to 24 years ', Aasanas: ['Garudasana', 'Vatayanasana', 'Budda Padamasana', 'Parivrutta Trikonasana', 'Sarvangasana', 'Matsyasana', 'Hanunanasana', 'Mayurasana',] },
                { minAge: 25, maxAge: 100, label: '25 and above ', Aasanas: ['Akarna Dhanurasana', 'Parivrutta Trikonasana', 'Paschimottanasan', 'Ushtrasana', 'Padma Sarvangasana', 'Dhanurasana', 'Tittibhasana', 'Eka Pada Chakrasana',] }
            ],
            female: [
                { minAge: 3, maxAge: 12, label: 'Upto 12 years ', Aasanas: ['Trikonasana', 'Virbhadrasana', 'Padahastasana', 'Akara Dhanurasana', 'Kukkutasana', 'Eka pada sikandasana', 'Vibhakta Paschimottanasana', 'Koundinyasana'] },
                { minAge: 13, maxAge: 18, label: '13 to 18 years ', Aasanas: ['Pachimottasana', 'Halasana', 'Purna Charkrasana', 'Parsvakonasana', 'Matsyendrasana', 'Purna Dhanurasana', 'Bakasana', 'Titishasana',] },
                { minAge: 19, maxAge: 24, label: '19 to 24 years ', Aasanas: ['Garudasana', 'Vatayanasana', 'Budda Padamasana', 'Parivrutta Trikonasana', 'Sarvangasana', 'Matsyasana', 'Hanunanasana', 'Mayurasana',] },
                { minAge: 25, maxAge: 100, label: '25 and above ', Aasanas: ['Akarna Dhanurasana', 'Parivrutta Trikonasana', 'Paschimottanasan', 'Ushtrasana', 'Padma Sarvangasana', 'Dhanurasana', 'Tittibhasana', 'Eka Pada Chakrasana',] }
            ],
        },
    },
    {
        title: 'Chess',
        img: chessImg,
        description: 'Test your strategic skills in intense chess matches.',
        tac: [
            {
                title: "Game Rules",
                content: "Standard Chess (International Chess) rules will be followed for all games."
            },
            {
                title: "Time Control",
                content: "Each game will have a fixed time limit of 25 minutes per player, without the use of chess clocks. Fischer clocks are not allowed."
            },
            {
                title: "Tiebreak System",
                content: "In the event of a tie, the Buchholz tiebreak system will be applied to determine the winner. Prize will not be shared in case of a tie."
            },
            {
                title: "Schedule and Rounds",
                content: "The number of rounds and the time schedule for the tournament will be announced at the venue."
            },
        ],
        ageGroups: {
            male: [
                { minAge: 3, maxAge: 12, label: 'Upto 12 years ' },
                { minAge: 13, maxAge: 16, label: '13 to 16 years ' },
                { minAge: 17, maxAge: 24, label: '17 to 24 years ' },
                { minAge: 25, maxAge: 45, label: '25 to 45 years ' },
                { minAge: 46, maxAge: 100, label: '46 and above ' }
            ],
            female: [
                { minAge: 3, maxAge: 12, label: 'Upto 12 years ' },
                { minAge: 13, maxAge: 16, label: '13 to 16 years ' },
                { minAge: 17, maxAge: 24, label: '17 to 24 years ' },
                { minAge: 25, maxAge: 45, label: '25 to 45 years ' },
                { minAge: 46, maxAge: 100, label: '46 and above ' }
            ],
        }
    },
    {
        title: 'Silambam',
        img: silambamImg,
        description: 'Witness the artistry of silambam in this unique event.',
        tac: [
            {
                title: "Equipment",
                content: "Participants must bring their own silambam sticks."
            }
        ],
        evaluation: {
            title: 'Participants will be evaluated based on the following criteria',
            list: [
                { title: 'Technique', content: 'Demonstrating proper form and technique while performing Silambam movements.' },
                { title: 'Accuracy', content: 'The precision and correctness of your movements and strikes.' },
                { title: 'Speed', content: 'The swiftness and agility in your Silambam performance.' },
                { title: 'Creativity', content: 'Displaying original and creative combinations of moves and strikes.' },
                { title: 'Control', content: 'Maintaining control over your weapon and movements.' },
                // { title: 'Defense', content: 'Effectively defending against simulated attacks.' },
                { title: 'Footwork', content: 'Your agility and footwork in executing Silambam techniques.' },
            ]
        },
        ageGroups: {
            male: [
                { minAge: 3, maxAge: 10, label: 'Upto 10 years ' },
                { minAge: 11, maxAge: 14, label: '11 to 14 years ' },
                { minAge: 15, maxAge: 18, label: '15 to 18 years ' },
                { minAge: 19, maxAge: 24, label: '19 to 24 years ' },
                { minAge: 25, maxAge: 100, label: '25 and above ' }
            ],
            female: [
                { minAge: 3, maxAge: 10, label: 'Upto 10 years ' },
                { minAge: 11, maxAge: 14, label: '11 to 14 years ' },
                { minAge: 15, maxAge: 18, label: '15 to 18 years ' },
                { minAge: 19, maxAge: 24, label: '19 to 24 years ' },
                { minAge: 25, maxAge: 100, label: '25 and above ' }
            ],
        }
    },
    {
        title: 'Kabaddi',
        img: kabadiImg,
        description: 'Get ready for intense team action in the kabaddi tournament.',
        tac: [
            {
                title: "Safety Equipment",
                content: "Ankle, palm, knee, and elbow supports are allowed if required for participant safety."
            },
            {
                title: "Team Size",
                content: "Each kabaddi team should consist of 10 members. Teams with fewer participants will be disqualified."
            }
        ],
        ageGroups: {
            male: [
                { label: 'Below 40kg ' },
                { label: 'Above 40kg ' },
            ],
        }

    },
    {
        title: 'Skating',
        img: skatingImg,
        description: 'Rollerblade your way to victory in the skating competition.',
        tac: [
            {
                title: "Equipment",
                content: "Participants must bring their own skating shoes and helmets, as well as other required safety equipment."
            }
        ],
        ageGroups: {
            male: [
                { minAge: 3, maxAge: 11, label: 'Upto 11 years ' },
                { minAge: 12, maxAge: 15, label: '12 to 15 years ' },
                { minAge: 16, maxAge: 20, label: '16 to 20 years ' },
                { minAge: 21, maxAge: 100, label: '21 and above ' }
            ],
            female: [
                { minAge: 3, maxAge: 11, label: 'Upto 11 years ' },
                { minAge: 12, maxAge: 15, label: '12 to 15 years ' },
                { minAge: 16, maxAge: 20, label: '16 to 20 years ' },
                { minAge: 21, maxAge: 100, label: '21 and above ' }
            ],
        }
    },
];

const termsAndConditions = [
    {
        title: "Safety First",
        content: "In case of injury, our first-aid team will provide necessary assistance to ensure your well-being.",
    },
    {
        title: "Fair Play",
        content: "While we encourage healthy competition, appeals against referee decisions will not be allowed. The referee's judgment is final.",
    },
    {
        title: "Punctuality Matters",
        content: "Be present when your name is called. Failure to do so may result in disqualification.",
    },
    {
        title: "Personal Belongings",
        content: "All valuables are your responsibility, so please take care of your belongings during the event.",
    },
    {
        title: "Stay Connected",
        content: "Upon confirmation of your registration, you will be added to our WhatsApp group after Nov 30 for event updates & communication.",
    },
    {
        title: "Uniformity",
        content: "Please wear the provided event t-shirt during all sports activities for easy identification.",
    },
    {
        title: "Eco-Friendly Zone",
        content: "Vysya Olympiks is a plastic-free zone. Please bring a stainless steel water bottle and avoid any plastic products.",
    },
    {
        title: "Zero Tolerance",
        content: "Use of drugs or any prohibited substances is strictly forbidden.",
    },
    {
        title: "Age Group Consideration",
        content: "In the event of a low registration count in a specific age group, participants may be combined with the nearest age group to ensure everyone gets to compete.",
    },
    {
        title: "Safety Gear",
        content: "For your safety, bring all necessary safety equipment as required for your chosen sports.",
    },
    {
        title: "Identification",
        content: "It is mandatory to carry a valid Aadhar card or any photo ID proof for verification.",
    },
    {
        title: "Geographic Limitations",
        content: "Participation is open to candidates from Tamil Nadu (TN) and Puducherry (Pondy) only.",
    },
    {
        title: "Traditional Dress Compulsory",
        content: "Please ensure you wear traditional dress in line with our tradition and heritage.",
    },
];

export const allSportsDataState = atom({
    key: 'allSportsDataState',
    default: sportsData
})

export const generalTACState = atom({
    key: 'generalTACState',
    default: termsAndConditions
})

export const donwDataState = atom({
    key: 'donwDataState',
    default: {}
})